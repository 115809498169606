<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-21 17:09:38
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-24 09:54:33
-->
<template>
  <a-row>
    <a-col :xl="16" :lg="12" :md="12" :sm="24" :xs="24">
      <a-card :border="false">
        <div class="sales_accounted_for">
          <a-row>
            <a-col :xl="24" :lg="12" :md="12" :sm="24" :xs="24">
              <SunburstStatis :id="'pro_accounted_for'" :option="charts" ref="SunburstStatis"></SunburstStatis>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-col>
    <a-col :xl="8" :lg="12" :md="12" :sm="24" :xs="24">
      <a-card :border="false">
        <rank-list title="经销商" style="padding: 0 15px 15px 0; height: 500px" :list="rankList" />
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import SunburstStatis from './highcharts/SunburstStatis.vue'
import { RankList } from '@/components'
const rankList = []
for (let i = 0; i < 13; i++) {
  rankList.push({
    name: '商品100010' + (i + 1),
    total: (1234.56 - i * 100).toFixed(2),
  })
}
let data = [
  {
    parent: 'NU',
    id: 'TOP_0',
    name: '总数',
    value: null,
  },
  {
    parent: 'TOP_0',
    id: 'CATE_1',
    name: '中央空调',
    // "value": 2461116.88
  },
  {
    parent: 'TOP_0',
    id: 'CATE_2',
    name: '家用空调',
  },
  {
    parent: 'TOP_0',
    id: 'CATE_3',
    name: '地暖',
  },
  {
    parent: 'TOP_0',
    id: 'CATE_4',
    name: '热水器',
  },
  {
    parent: 'TOP_0',
    id: 'CATE_5',
    name: '空气净化器',
  },
  {
    parent: 'CATE_1',
    id: 'CATE_1_1',
    name: 'FDE系列',
  },
  {
    parent: 'CATE_1_1',
    id: 'PRO_1',
    name: '三菱空调机_FDE56KXZE1',
    value: 6000,
  },
  {
    parent: 'CATE_1_1',
    id: 'PRO_2',
    name: '三菱空调机_FDE36KXE6F',
    value: 4350,
  },
  {
    parent: 'CATE_1_1',
    id: 'PRO_3',
    name: '三菱空调机_FDE140KXE6F ',
    value: 200,
  },
  {
    parent: 'CATE_1_1',
    id: 'PRO_4',
    name: ' 三菱空调机_FDE45KXZE1 ',
    value: 1590,
  },

  {
    parent: 'CATE_1',
    id: 'CATE_1_2',
    name: 'FDC系列',
  },
  {
    parent: 'CATE_1_2',
    id: 'PRO_5',
    name: '室外机_FDC280KXDE2GQ',
    value: 3500,
  },
  {
    parent: 'CATE_1_2',
    id: 'PRO_6',
    name: '室外机_FDC255KXDE2GQ',
    value: 900,
  },
  {
    parent: 'CATE_1_2',
    id: 'PRO_7',
    name: '室外机_FDC560KXDE2GQ',
    value: 500,
  },
  {
    parent: 'CATE_1',
    id: 'CATE_1_3',
    name: 'FDFL系列',
  },
  {
    parent: 'CATE_1_3',
    id: 'PRO_8',
    name: '三菱空调机_FDFL71KXE6F',
    value: 500,
  },
  {
    parent: 'CATE_1_3',
    id: 'PRO_9',
    name: '三菱空调机_FDFL71KXE6F1',
    value: 590,
  },
  {
    parent: 'CATE_1_3',
    id: 'PRO_10',
    name: '三菱空调机_FDFL28KXE6F',
    value: 333,
  },
  {
    parent: 'CATE_1',
    id: 'CATE_1_4',
    name: 'FDK系列',
  },
  {
    parent: 'CATE_1_4',
    id: 'PRO_11',
    name: '三菱空调机_FDFL71KXE6F',
    value: 500,
  },
  {
    parent: 'CATE_1_4',
    id: 'PRO_12',
    name: '三菱空调机_FDFL71KXE6F1',
    value: 590,
  },
  {
    parent: 'CATE_1_4',
    id: 'PRO_13',
    name: '三菱空调机_FDFL28KXE6F',
    value: 333,
  },

  {
    parent: 'CATE_2',
    id: 'CATE_2_1',
    name: '预售款',
    value: 0,
  },
  {
    parent: 'CATE_2',
    id: 'CATE_2_2',
    name: 'APV',
    value: 0,
  },
  {
    parent: 'CATE_2',
    id: 'CATE_2_3',
    name: 'MF',
    value: 0,
  },
  {
    parent: 'CATE_3',
    id: 'CATE_3_1',
    name: '地暖（主材）',
    value: 0,
  },
  {
    parent: 'CATE_3',
    id: 'CATE_3_2',
    name: '地暖（辅材）',
    value: 0,
  },
  {
    parent: 'CATE_3',
    id: 'CATE_3_3',
    name: '地板',
    value: 0,
  },
]

export default {
  name: 'pro_accounted_for',

  components: {
    SunburstStatis,

    RankList,
  },

  data() {
    return {
      rankList,
      charts: {
        chart: {
          height: '500px',
        },

        title: {
          text: '商品分类销售占比',
        },
        series: [
          {
            type: 'sunburst',
            data: [],
            allowDrillToNode: true,
            cursor: 'pointer',
            dataLabels: {
              formatter: function () {
                let shape = this.point.node.shapeArgs
                let innerArcFraction = (shape.end - shape.start) / (2 * Math.PI)
                let perimeter = 2 * Math.PI * shape.innerR

                let innerArcPixels = innerArcFraction * perimeter

                if (innerArcPixels > 16) {
                  return this.point.name
                }
              },
            },
            levels: [
              {
                level: 2,
                colorByPoint: true,
                dataLabels: {
                  rotationMode: 'parallel',
                },
              },
              {
                level: 3,
                colorVariation: {
                  key: 'brightness',
                  to: -0.5,
                },
              },
              {
                level: 4,
                colorVariation: {
                  key: 'brightness',
                  to: 0.5,
                },
              },
            ],
          },
        ],
        tooltip: {
          headerFormat: '',
          pointFormat: '<b>{point.name}</b>的占比是：<b>{point.value}</b>',
        },
        credits: {
          enabled: false,
        },
      },

      dataList: [],
    }
  },

  computed: {},

  created() {
    this.getCharts()
  },

  methods: {
    getCharts() {
      this.axios.get(`/api/base/system/report/getProCollectionStatistics`).then((res) => {
        console.log(res.body)
        this.$set(this.charts.series[0], 'data', res.body)
        this.$refs.SunburstStatis.getCharts()
      })
      this.axios.get(`/api/base/system/report/getProListStatistics`).then((res) => {
        console.log(res.body)
        let list = res.body;
        let rankList = [];
        for (let i = 0; i < list.length; i += 1) {
          rankList.push({
            name: list[i][0],
            total: list[i][1]
          })
        }
        this.rankList = rankList;
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>